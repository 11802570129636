import styled, { CSSProperties } from "styled-components";
import { ReactNode } from "react";
import Icon from "../Icon";
import Typography from "../Typo/Typography";

const LabelButton = styled.button<{
    type: "primary" | "secondary" | "terciary" | "success";
    size: "smaller" | "small" | "large";
    state: "default" | "hover" | "disable";
    hasLabel: boolean;
    ghost: boolean;
}>`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;

    padding: ${(props) =>
            props.size === "large"
                    ? "0"
                    : props.size === "smaller"
                            ? "4px 8px"
                            : "6px 12px"};

    width: ${(props) =>
            props.size === "large"
                    ? "48px"
                    : props.size === "smaller"
                            ? "32px"
                            : "auto"};
    height: ${(props) =>
            props.size === "large"
                    ? "48px"
                    : props.size === "smaller"
                            ? "32px"
                            : "40px"};

    border: ${(props) =>
            props.type === "success"
                    ? "1px solid #6EE7B7"
                    : "1px solid #CBD5E1"};

    background: ${(props) =>
            props.ghost
                    ? "transparent"
                    : props.type === "success"
                            ? "#ECFDF5"
                            : props.state === "default"
                                    ? "#FFF"
                                    : props.state === "hover"
                                            ? "#F7FAFC"
                                            : "#CBD5E1"};

    &:hover {
        background: ${(props) =>
                props.type === "success" && !props.ghost
                        ? "#D1FAE5"
                        : props.ghost
                                ? "transparent"
                                : props.state === "hover"
                                        ? "#F7FAFC"
                                        : "#F1F5F9"};
    }
`;

interface ButtonProps {
    type: "primary" | "secondary" | "terciary" | "success";
    size: "smaller" | "small" | "large";
    state: "default" | "hover" | "disable";
    label?: string;
    primaryIcon?: ReactNode | string;
    secondaryIcon?: ReactNode | string;
    onClick: () => void;
    style?: CSSProperties;
    ghost?: boolean;
}

const Button = ({ type, size, state, label, primaryIcon, secondaryIcon, onClick, style, ghost = false }: ButtonProps) => {
    return (
        <LabelButton
            type={type}
            size={size}
            state={state}
            hasLabel={!!label}
            ghost={ghost}
            onClick={onClick}
            style={style}
        >
            {typeof primaryIcon === "string" ? (
                <Icon type={primaryIcon} width="16px" height="16px" color="#475569" />
            ) : (
                primaryIcon
            )}
            {label && <Typography type={size === "smaller" ? "label-smaller" : "label-small"} text={label} />}
            {typeof secondaryIcon === "string" ? (
                <Icon type={secondaryIcon} width="16px" height="16px" color="#475569" />
            ) : (
                secondaryIcon
            )}
        </LabelButton>
    );
};

export default Button;
