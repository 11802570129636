import React from 'react';
import styled from 'styled-components';
import { CSSProperties, ReactNode } from 'react';

const CardContainer = styled.div`
  display: flex;
  width: 943px;
  padding: 16px;
  align-items: flex-start;
  border-radius: 8px;
  background: #FFF;
    gap: 8px;
`;

interface CardProps {
    children: ReactNode;
    style?: CSSProperties;
}


const Card = (props: CardProps) => {
    const { children, style } = props;

    return <CardContainer style={style}>{children}</CardContainer>;
};

export default Card;
