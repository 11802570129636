import React from 'react';
import styled from 'styled-components';
import Typography from '../Typo/Typography';

const FooterWrapper = styled.div`
    width: 100%;
    background-color: #f7f7f8;
`;

const FooterContainer = styled.footer`
    max-width: 1050px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
`;

const Footer = () => {
    return (
        <FooterWrapper>
            <FooterContainer>
                <Typography
                    type="label-smaller-grey"
                    text="Lorem ipsum dolor sit amet consectetur. Praesent egestas iaculis viverra neque tempor lobortis. Risus cursus velit amet tincidunt et penatibus sed condimentum. Accumsan turpis eu tincidunt viverra odio tortor leo eu. Scelerisque maecenas risus ornare leo volutpat eros consequat lorem lorem."
                />
            </FooterContainer>
        </FooterWrapper>
    );
};

export default Footer;
