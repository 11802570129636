import React from 'react';
import styled from 'styled-components';
import { ReactNode } from 'react';

const statusColors = {
    success: {
        background: "#3F7CFA",
        text: "#FFF",
    },
    warning: {
        background: "#FEF3C7",
        text: "#92400E",
    },
    error: {
        background: "#FEE2E2",
        text: "#B91C1C",
    },
    default: {
        background: "#10B981",
        text: "#FFF",
    },
};

const BadgeContainer = styled.div<{ status: keyof typeof statusColors }>`
    display: inline-flex;
    height: 20px;
    padding: 0 6px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    flex-shrink: 0;
    background-color: ${(props) => statusColors[props.status].background};
    border-radius: 8px;
`;

const BadgeText = styled.span<{ status: keyof typeof statusColors }>`
  font-size: 12px;
  color: ${(props) => statusColors[props.status].text};
  font-weight: 500;
`;

interface BadgeProps {
    text: string;
    icon?: ReactNode;
    status?: keyof typeof statusColors;
}

function Badge({ text, icon, status = "default" }: BadgeProps) {
    return (
        <BadgeContainer status={status}>
            {icon && <>{icon}</>}
            <BadgeText status={status}>{text}</BadgeText>
        </BadgeContainer>
    );
}

export default Badge;
