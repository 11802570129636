import IconMenu from '../../assets/icons/menu.svg';
import IconHome from '../../assets/icons/home.svg';
import IconWindow from '../../assets/icons/app-window.svg';
import IconSettings from '../../assets/icons/settings.svg';
import IconChip from '../../assets/icons/chip.svg';
import IconArrowRight from '../../assets/icons/arrow-right.svg';
import IconEmployee from '../../assets/icons/employee.svg';

interface IconProps {
    width: string;
    height: string;
    color: string;
    type: string;
}

const Icon = ({width, height, color, type}: IconProps) => {
    return (
        <>
            {type === "menu" && <img src={IconMenu} alt="menu" width={width} height={height} style={{fill: color}} />}
            {type === "home" && <img src={IconHome} alt="home" width={width} height={height} style={{fill: color}} />}
            {type === "window" && <img src={IconWindow} alt="window" width={width} height={height} style={{fill: color}} />}
            {type === "settings" && <img src={IconSettings} alt="settings" width={width} height={height} style={{fill: color}} />}
            {type === "chip" && <img src={IconChip} alt="chip" width={width} height={height} style={{fill: color}} />}
            {type === "arrow-right" && <img src={IconArrowRight} alt="arrow-right" width={width} height={height} style={{fill: color}} />}
            {type === "employee" && <img src={IconEmployee} alt="employee" width={width} height={height} style={{fill: color}} />}
        </>
    );
}

export default Icon;