import styled, {CSSProperties} from "styled-components";

const LabelSmallest = styled.div`
    font-family: "Satoshi", sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 13px;
    color: #334155;
`;

const LabelSmaller = styled.div`
  font-family: "Satoshi", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  color: #475569;
`;

const LabelSmallerGrey = styled.div`
  font-family: "Satoshi", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  color: #94A3B8;
`;

const LabelSmall = styled.div`
  font-family: "Satoshi", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  color: #475569;
`;

const LabelNeutralMedium = styled.div`
    font-family: "Satoshi", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    color: #94A3B8;
`;

const LabelHight = styled.div`
    font-family: "Satoshi", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: #94A3B8;
`;

const LabelHighBlack = styled.div`
    font-family: "Satoshi", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: #475569;
`;

const LabelHighter = styled.div`
    font-family: "Satoshi", sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 900;
    line-height: 32px;
    color: #334155;
`;

const LabelBadgePrimary = styled.div`
    font-family: "Satoshi", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    color: #FFFFFF;
`;

interface TypographyProps {
  type: "label-smallest" | "label-smaller" | "label-smaller-grey" | "label-small" | "label-medium" | "label-hight" | "label-highter" | "label-hight-black" | "label-badge-primary"
  text: string
  style?: CSSProperties
}

const Typography = ({type, text, style}: TypographyProps) => {

  return (
    <div>
        {type === "label-smallest" && <LabelSmallest style={style}>{text}</LabelSmallest>}
        {type === "label-smaller" && <LabelSmaller style={style}>{text}</LabelSmaller>}
        {type === "label-smaller-grey" && <LabelSmallerGrey style={style}>{text}</LabelSmallerGrey>}
        {type === "label-small" && <LabelSmall style={style}>{text}</LabelSmall>}
        {type === "label-medium" && <LabelNeutralMedium style={style}>{text}</LabelNeutralMedium>}
        {type === "label-hight" && <LabelHight style={style}>{text}</LabelHight>}
        {type === "label-highter" && <LabelHighter style={style}>{text}</LabelHighter>}
        {type === "label-hight-black" && <LabelHighBlack style={style}>{text}</LabelHighBlack>}
        {type === "label-badge-primary" && <LabelBadgePrimary style={style}>{text}</LabelBadgePrimary>}
    </div>
  )
}

export default Typography;