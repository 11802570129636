import LogoImage from '../../../assets/images/logo.svg';

interface LogoProps {
    width: string;
    height: string;
}

const Logo = ({ width, height }: LogoProps) => {
    return (
        <img src={LogoImage} alt="logo" width={width} height={height} />
    );
}

export default Logo;
