import React from 'react';
import styled from 'styled-components';

const sizes = {
    xs: 8,
    s: 16,
    m: 32,
    l: 40,
    xl: 80,
};

const AvatarWrapper = styled.div<{ size: keyof typeof sizes }>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${(props) => `${sizes[props.size]}px`};
    height: ${(props) => `${sizes[props.size]}px`};
    border-radius: 9999px;
    overflow: hidden;
    background-color: #e2e8f0;
    cursor: pointer;
`;

const AvatarImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

interface AvatarProps {
    size: keyof typeof sizes;
    src: string;
    alt?: string;
    onClick: () => void;
    style?: React.CSSProperties;
}

const Avatar = ({ size, src, alt, style }: AvatarProps) => {
    return (
        <AvatarWrapper size={size} style={style}>
            <AvatarImage src={src} alt={alt || 'avatar'} />
        </AvatarWrapper>
    );
};

export default Avatar;
