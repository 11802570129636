import React from 'react';
import styled from 'styled-components';
import Icon from './Icon';
import Typography from './Typo/Typography';

const BoxContainer = styled.div<{ boxColor: string }>`
    display: flex;
    width: 80px;
    height: 80px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    background-color: #fff;
    border-radius: 8px;
    border: 2px solid ${({ boxColor }) => boxColor};
    flex-direction: column;
    gap: 8px;
`;

interface IconBoxProps {
    text: string;
    iconType: string;
    iconColor: string;
    boxColor: string;
}

const IconBox = ({ text, iconType, iconColor, boxColor }: IconBoxProps) => {
    return (
        <BoxContainer boxColor={boxColor}>
            <Icon type={iconType} width="22px" height="22px" color={iconColor} />
            <Typography type="label-smallest" text={text} />
        </BoxContainer>
    );
};

export default IconBox;
