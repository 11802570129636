import React from "react";
import Header from "../../components/global/Header/Header";
import Footer from "../../components/global/Footer/Footer";
import styled from "styled-components";
import Typography from "../../components/global/Typo/Typography";
import Card from "../../components/global/Card/Card";
import Badge from "../../components/global/Badge/Badge";
import IconChip from "../../assets/icons/chip.svg";
import Button from "../../components/global/Button/Button";
import IconBox from "../../components/global/IconApp";

const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
`;

const DashboardContainer = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 40px;
    background-color: #f7f7f8;
`;

const RowContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 40px;
`;

const TextContainer = styled.div`
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
`;

const BadgesContainer = styled.div`
    display: flex;
    gap: 9px;
`;

const CardLayout = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 16px;
`;

const TextAndBadgesContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const Icon = styled.img`
    width: 12px;
    height: 12px;
`;

const ApplicationsContainer = styled.div`
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

const BoxesContainer = styled.div`
    display: flex;
    width: 900px;
    padding: 40px 0;
    align-items: flex-start;
    align-content: flex-start;
    gap: 32px;
    flex-wrap: wrap;
    margin: 0 auto;
`;

const Dashboard = () => {
    return (
        <PageContainer>
            <Header />

            <DashboardContainer>
                <RowContainer>
                    <TextContainer>
                        <Typography type="label-highter" text="Bon retour parmi nous, Fabrice !" />
                        <Typography type="label-hight" text="Lorem ipsum dolor sit amet consectetur. Nibh fames faucibus mi morbi fermentum auctor lorem erat." />
                    </TextContainer>

                    <Card>
                        <CardLayout>
                            <TextAndBadgesContainer>
                                <BadgesContainer>
                                    <Badge text="Tag" status="success" icon={<Icon src={IconChip} />} />
                                    <Badge text="Tag" status="default" icon={<Icon src={IconChip} />} />
                                </BadgesContainer>
                                <Typography
                                    type="label-hight-black"
                                    text="Lorem ipsum dolor sit amet consectetur. Adipiscing tristique mi sed urna pretium integer mi non. Semper ut ut mauris mattis enim maecenas magna. Ac orci augue scelerisque eget faucibus diam. Facilisi nec vitae molestie ut arcu sed eleifend."
                                />
                            </TextAndBadgesContainer>

                            <ButtonContainer>
                                <Button
                                    type="success"
                                    size="large"
                                    state="default"
                                    onClick={() => console.log('Card clicked')}
                                    primaryIcon="arrow-right"
                                />
                            </ButtonContainer>
                        </CardLayout>
                    </Card>
                </RowContainer>

                <ApplicationsContainer>
                    <Typography type="label-highter" text="Mes applications" />

                    <BoxesContainer>
                        <IconBox
                            text="Employés"
                            iconType="employee"
                            iconColor="#EA4335"
                            boxColor="#EA4335"
                        />
                        <IconBox
                            text="Employés"
                            iconType="employee"
                            iconColor="#EA4335"
                            boxColor="#EA4335"
                        />
                        <IconBox
                            text="Employés"
                            iconType="employee"
                            iconColor="#EA4335"
                            boxColor="#EA4335"
                        />
                        <IconBox
                            text="Employés"
                            iconType="employee"
                            iconColor="#EA4335"
                            boxColor="#EA4335"
                        />
                        <IconBox
                            text="Employés"
                            iconType="employee"
                            iconColor="#EA4335"
                            boxColor="#EA4335"
                        />
                        <IconBox
                            text="Employés"
                            iconType="employee"
                            iconColor="#EA4335"
                            boxColor="#EA4335"
                        />
                        <IconBox
                            text="Employés"
                            iconType="employee"
                            iconColor="#EA4335"
                            boxColor="#EA4335"
                        />
                        <IconBox
                            text="Employés"
                            iconType="employee"
                            iconColor="#EA4335"
                            boxColor="#EA4335"
                        />
                        <IconBox
                            text="Employés"
                            iconType="employee"
                            iconColor="#EA4335"
                            boxColor="#EA4335"
                        />
                        <IconBox
                            text="Employés"
                            iconType="employee"
                            iconColor="#EA4335"
                            boxColor="#EA4335"
                        />
                        <IconBox
                            text="Employés"
                            iconType="employee"
                            iconColor="#EA4335"
                            boxColor="#EA4335"
                        />
                        <IconBox
                            text="Employés"
                            iconType="employee"
                            iconColor="#EA4335"
                            boxColor="#EA4335"
                        />
                        <IconBox
                            text="Employés"
                            iconType="employee"
                            iconColor="#EA4335"
                            boxColor="#EA4335"
                        />
                        <IconBox
                            text="Employés"
                            iconType="employee"
                            iconColor="#EA4335"
                            boxColor="#EA4335"
                        />
                        <IconBox
                            text="Employés"
                            iconType="employee"
                            iconColor="#EA4335"
                            boxColor="#EA4335"
                        />
                        <IconBox
                            text="Employés"
                            iconType="employee"
                            iconColor="#EA4335"
                            boxColor="#EA4335"
                        />
                        <IconBox
                            text="Employés"
                            iconType="employee"
                            iconColor="#EA4335"
                            boxColor="#EA4335"
                        />
                        <IconBox
                            text="Employés"
                            iconType="employee"
                            iconColor="#EA4335"
                            boxColor="#EA4335"
                        />
                        <IconBox
                            text="Employés"
                            iconType="employee"
                            iconColor="#EA4335"
                            boxColor="#EA4335"
                        />
                        <IconBox
                            text="Employés"
                            iconType="employee"
                            iconColor="#EA4335"
                            boxColor="#EA4335"
                        />
                        <IconBox
                            text="Employés"
                            iconType="employee"
                            iconColor="#EA4335"
                            boxColor="#EA4335"
                        />
                        <IconBox
                            text="Employés"
                            iconType="employee"
                            iconColor="#EA4335"
                            boxColor="#EA4335"
                        />
                        <IconBox
                            text="Employés"
                            iconType="employee"
                            iconColor="#EA4335"
                            boxColor="#EA4335"
                        />
                        <IconBox
                            text="Employés"
                            iconType="employee"
                            iconColor="#EA4335"
                            boxColor="#EA4335"
                        />
                    </BoxesContainer>
                </ApplicationsContainer>
            </DashboardContainer>

            <Footer />
        </PageContainer>
    );
};

export default Dashboard;
